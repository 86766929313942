// @import "~mdb-ui-kit/src/plugins/multi-carousel/scss/multi-carousel.scss";
// @import "~mdb-ui-kit/src/plugins/countdown/scss/countdown.scss";
// @import "~mdb-ui-kit/src/plugins/file-upload/scss/file-upload.scss";
// @import "main_page";
// @import "~mdb-ui-kit/src/mdb/scss/mdb.free.scss";
// @import "~mdb-ui-kit/src/mdb/scss/mdb.pro.scss";

@import '~mdb-ui-kit/css/mdb.min.css';
@import '~mdb-multi-carousel/css/multi-carousel.min.css';

.jumbotron {
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}

// MDBootstrap - Netflix Template

.modal-body-preview img {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.multi-carousel .multi-carousel-inner .multi-carousel-item img {
  max-height: 400px;
}

.modal-body-preview {
  position: relative;
}

.preview-btn-container {
  position: absolute;
  bottom: 50px;
  left: 10px;
}

.btn-close-wrapper {
  position: absolute;
  display: flex;
  padding: 0.5rem;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 100%;
  z-index: 1;
}

.maturity-number {
  border: 1px solid black;
  padding: 0.1rem 0.5rem;
}

.preview-tags {
  font-size: 0.8rem;
}

.video-fluid {
  width: 100%;
}

.movie-list {
  position: relative;
  top: -50vh;
}

.video-gradient {
  position: relative;
  width: 100%;
  height: 20vh;
  bottom: 20vh;
  /* stylelint-disable-next-line number-leading-zero */
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .15) 15%, rgba(255, 255, 255, .35) 29%, rgba(255, 255, 255, .58) 44%, rgba(255, 255, 255, .72) 68%, rgba(255, 255, 255, .87) 90%, rgba(255, 255, 255, 1) 100%);
}

.modal-image-gradient {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  /* stylelint-disable-next-line number-leading-zero */
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .15) 15%, rgba(255, 255, 255, .35) 29%, rgba(255, 255, 255, .58) 44%, rgba(255, 255, 255, .72) 68%, rgba(255, 255, 255, 1) 100%);
}

.about-this-movie {
  position: absolute;
  top: 33vh;
}
